import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { CustomParlay, SGPOptions } from '../interfaces';

const SGPWrapper = styled.div`
  margin: 0px;
`;

type SGPProps = {
  options: SGPOptions;
  containerId: string;
  title?: string;
  currentLocation?: Route;
  initiallyCollapsed?: boolean;
  maxLegs?: number;
  customParlays?: CustomParlay[];
}

function SGP({
  options,
  currentLocation,
  containerId,
  title,
  initiallyCollapsed,
  maxLegs,
  customParlays,
}: SGPProps) {
  const groupId = useMemo(() => {
    if (options.type === 'groupId') {
      return options.groupId;
    }

    if (options.type === 'auto' && currentLocation?.params.group) {
      return Number(currentLocation.params.group);
    }

    return undefined;
  }, [options, currentLocation?.params.group]);

  useEffect(() => {
    const rsiSgpContentClient = RSIClient.load(RSIClient.modules.RSI_SGP_CONTENT, {
      containerId,
      props: {
        variant: 'ParlayWidget',
        options: {
          groupId,
          title,
          maxLegs,
          initiallyCollapsed,
          customParlays,
        },
      },
    });
    return () => rsiSgpContentClient?.destroy?.();
  }, [containerId, groupId, title, initiallyCollapsed, maxLegs, customParlays]);

  return (
    <SGPWrapper id={containerId} />
  );
}

SGP.defaultProps = {
  title: undefined,
  currentLocation: undefined,
  initiallyCollapsed: false,
  maxLegs: undefined,
  customParlays: [],
};

export default React.memo(SGP);
