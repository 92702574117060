export enum ScheduleTypesEnum {
  DATE_TIME_RANGE = 'datetimerange',
  HOUR_RANGE = 'hourrange',
  DAY_OF_WEEK = 'dayOfWeek',
  MONTH = 'month',
  YEAR = 'year',
}

export type DateTimeRangeSchedule = {
  type: typeof ScheduleTypesEnum.DATE_TIME_RANGE
  startTime?: Date,
  endTime?: Date
}

export type HourRangeSchedule = {
  type: typeof ScheduleTypesEnum.HOUR_RANGE
  startHour: number,
  endHour: number
}

export type ValueSchedule = {
  type: typeof ScheduleTypesEnum.DAY_OF_WEEK
  | typeof ScheduleTypesEnum.MONTH
  | typeof ScheduleTypesEnum.YEAR,
  value: number
}

export type Schedule = DateTimeRangeSchedule | HourRangeSchedule | ValueSchedule;

export type ScheduleGroup = Schedule[];
