import React, { useEffect } from 'react';
import { VideoPlayerOptions } from '../interfaces';

type VideoPlayerProps = {
  source: string,
  options: VideoPlayerOptions,
  containerId: string,
}

function VideoPlayer({ source, options, containerId }: VideoPlayerProps) {
  useEffect(() => {
    let destroy: () => void;
    if (source && options) {
      ({ destroy } = RSIContentPlayerClient.init({
        containerId,
        initialArgs: {
          type: 'video',
          source,
          autoPlay: options.autoPlay,
          loop: options.loop,
          fullscreenEnabled: options.fullscreenEnabled,
          pipEnabled: options.pipEnabled,
        },
      }));
    }
    return () => destroy?.();
  }, [source, options, containerId]);
  return (
    <div
      id={containerId}
    />
  );
}

export default VideoPlayer;
