/* eslint-disable import/prefer-default-export */
import { logger } from '../services';

export function conditionMatches(conditions: any, condition: string): boolean {
  if (!condition) {
    return true;
  }

  if (!conditions) {
    return false;
  }

  try {
    // eslint-disable-next-line no-eval
    return !!eval(`${conditions} if (${condition}) {true;} else {false;}`);
  } catch (error) {
    const e = error as Error;
    logger.error('Failed to evaluate conditionMatches', {
      error: e.toString(),
      condition,
    });
    return false;
  }
}
