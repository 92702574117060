/* eslint-disable react/jsx-no-undef */
import React from 'react';

type FavoritesProps = {
  initiallyCollapsed?: boolean;
  hideOnEmptyFavorites?: boolean;
};

function Favorites({ initiallyCollapsed, hideOnEmptyFavorites }: FavoritesProps) {
  return (
    <RSIClient.UMDLoader
      name={RSIClient.modules.MFE_SPORTS_FAVORITES_WIDGET}
      props={{
        initiallyCollapsed,
        hideOnEmptyFavorites,
      }}
    />
  );
}

Favorites.defaultProps = {
  initiallyCollapsed: false,
  hideOnEmptyFavorites: false,
};

export default Favorites;
