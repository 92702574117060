import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css, ThemeProvider } from 'styled-components';
import { BetOffer as BetOfferType, Breakpoint, OddsFormat } from '../../interfaces/EventWidget';
import BetOffer from './BetOffer';
import Time from './Time';
import { ReactComponent as StatsIcon } from './assets/icon-chart-line-up-square.svg';
import { ReactComponent as MoreBetsIcon } from './assets/icon-plus-square.svg';

const EventWrapper = styled.div`
  margin: 0px;
  border-bottom: 0;
  padding: 14px 16px 16px;
  display: flex;
  flex-direction: column;
  font-family: 'DM Sans', sans-serif;
  background: ${({ theme: { colors } }) => colors.white};
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;

`;

const Header = styled.span`
  position: relative;
  display: flex;
  flex-direction: column;
  color:${({ theme: { colors } }) => colors.grey};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding-bottom: 4px;

  &:after {
    position: absolute;
    content: "";
    height: 1px;
    bottom: 1px;
    right: -16px;
    left: -16px;
    background: linear-gradient(90deg, ${({ theme: { colors } }) => colors.blue} 18.58%, ${({ theme: { colors } }) => colors.grey3} 100%);
  }

  ${({ theme: { width } }) => width > Breakpoint.MEDIUM && css`
    font-size: 14px;
    line-height: 18px;
  `}
`;

const Title = styled.div`
  color: ${({ theme: { colors } }) => colors.black};
  font-weight: 500;
  margin-bottom: 2px;

  ${({ theme: { width } }) => width > Breakpoint.MEDIUM && css`
    margin-bottom: 4px;
  `}
`;

const HeaderPrepend = styled.span`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const HeaderActions = styled.span`
  display: flex;
  margin-left: auto;
`;

const HeaderAction = styled.button`
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:not(:first-of-type) {
    margin-left: 6px;

    ${({ theme: { width } }) => width > Breakpoint.MEDIUM && css`
      margin-left: 14px;
    `}
  }

  svg {
    fill: ${({ theme: { colors } }) => colors.grey};
    margin-right: 4px;
    display: flex;
    align-items: center;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: ${({ theme: { colors } }) => colors.blue};

      svg {
        fill: ${({ theme: { colors } }) => colors.blue};
      }
    }
  }
`;

type EventProps = {
  id: number;
  title: string;
  start: string;
  offerCount: number;
  betOffers: BetOfferType[];
  dateTimeFormat: string;
  timezone: string;
  stats: boolean;
  sportsEventEnabled: boolean;
}

function Event({
  id, title, start, offerCount, betOffers,
  dateTimeFormat, timezone, stats, sportsEventEnabled,
}: EventProps) {
  const [selectedOutcomes, setSelectedOutcomes] = useState<number[]>([]);
  const [oddsFormat, setOddsFormat] = useState<OddsFormat>(OddsFormat.DECIMAL);
  const [parentNode, setParentNode] = useState<HTMLDivElement>();
  const [theme, setTheme] = useState({
    width: 0,
    colors: {
      black: '#000',
      black2: '#000335',
      white: '#fff',
      grey: '#717881',
      grey2: '#DFE9F4',
      grey3: '#E2E4F1',
      grey4: '#EFF7FF',
      blue: '#316094',
      blue2: '#E8EFF7',
      blue3: '#1D355B',
      gold: '#DEA117',
      gold2: '#C9A249',
      gold3: '#FFD02C',
      gold4: '#FA0',
    },
  });

  useEffect(() => {
    // TODO: Add type definitions
    const unsubscribe = RSIKambiController.subscribe(({ betslip }: any) => {
      setSelectedOutcomes(betslip.selectedOutcomes);
    });
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    const oddsFormatSubscription = RSIEventBus.subscribe(
      RSIEventBus.eventTypes.SPORTSBOOK_ODDS_FORMAT_CHANGE,
      (newValue: OddsFormat) => {
        setOddsFormat(newValue);
      },
    );
    return () => oddsFormatSubscription.unsubscribe();
  }, []);

  const measuredRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setParentNode(node);
    }
  }, []);

  useLayoutEffect(() => {
    const setThemeWidth = () => {
      if (parentNode) {
        setTheme((currentTheme) => ({
          ...currentTheme,
          width: parentNode.getBoundingClientRect().width,
        }));
      }
    };
    if (parentNode) {
      setThemeWidth();
      window.addEventListener('resize', setThemeWidth);
    }
    return () => window.removeEventListener('resize', setThemeWidth);
  }, [parentNode]);

  if (!betOffers.every((betOffer) => betOffer.outcomes.every((outcome) => outcome.status !== 'SUSPENDED'))) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <div ref={measuredRef}>
        <EventWrapper>
          <Header>
            <HeaderPrepend>
              <Time
                time={start}
                format={dateTimeFormat}
                timezone={timezone}
              />
              <HeaderActions>
                {stats && (
                  <HeaderAction type="button" onClick={() => RSIEventBus.publish(RSIEventBus.eventTypes.SHOW_EVENT_STATISTICS, id)}>
                    <StatsIcon width={12} height={12} />
                    <FormattedMessage id="STATS" />
                  </HeaderAction>
                )}
                <HeaderAction
                  type="button"
                  onClick={() => RSINavigationHandler.push({
                    page: 'sportsbook',
                    params: {
                      ...RSINavigationHandler.getCurrentLocation().params,
                      feed: undefined,
                      group: undefined,
                      type: undefined,
                      section: undefined,
                      event: sportsEventEnabled ? true : undefined,
                    },
                    hash: `event/${id}`,
                  })}
                >
                  <MoreBetsIcon width={12} height={12} />
                  {offerCount}
                  {' '}
                  <FormattedMessage id="BETS" />
                </HeaderAction>
              </HeaderActions>
            </HeaderPrepend>
            <Title>
              {title}
            </Title>
          </Header>
          {betOffers.map((betOffer) => (
            <BetOffer
              key={`betOffer-${betOffer.id}`}
              betOffer={betOffer}
              oddsFormat={oddsFormat}
              selectedOutcomes={selectedOutcomes}
            />
          ))}
        </EventWrapper>
      </div>
    </ThemeProvider>
  );
}

export default Event;
