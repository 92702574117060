/* eslint-disable react/jsx-no-undef */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { SportsEventOptions, SportsEventType } from '../interfaces';
import { Event } from '../interfaces/EventWidget';
import logger from '../services/logger';

const SportsEventWrapper = styled.div`
  margin: 0px;
  border-radius: 8px;
  box-shadow: 0 3px 4px #B2B8C1;
`;

type SportsEventProps = {
  containerId: string;
  betOfferId: number;
  options?: SportsEventOptions;
};

function SportsEvent({ betOfferId, options, containerId }: SportsEventProps) {
  const [eventDetails, setEventDetails] = useState<Event>();

  useEffect(() => {
    const fetchEventDetails = async (id: number) => {
      try {
        const eventData = await RSISportsData.fetchEventInfoByBetOfferId(id);

        setEventDetails(eventData);
      } catch (err) {
        logger.warn(`Failed to retrieve event info by betOfferId=${id}`, {
          ...(err instanceof Error && { err: err.message || err.toString() }),
        });
      }
    };

    fetchEventDetails(betOfferId);
  }, [betOfferId]);

  return (
    <SportsEventWrapper id={containerId}>
      <RSIClient.UMDLoader
        name={RSIClient.modules.RSI_SPORTS_EVENT}
        props={{
          event: eventDetails,
          options: {
            ...options,
            type: SportsEventType.HOUSE_SPECIALS,
          },
          betOfferId,
        }}
      />
    </SportsEventWrapper>
  );
}

SportsEvent.defaultProps = {
  options: undefined,
};

export default SportsEvent;
