export type Event = {
  id: number;
  name: string;
  start: string;
  format: EventFormat;
  sport: string;
  state: EventState;
  offerCount: number;
  betOffers: BetOffer[];
}

export type BetOffer = {
  id: number;
  betOfferType: string;
  betDescription: string;
  outcomes: Outcome[];
  status: Status;
}

export type Outcome = {
  id: number;
  type: string;
  odds: number;
  oddsAmerican: string;
  oddsFractional: string;
  label: string;
  line: number;
  status: Status;
  cashOutStatus: string;
}

export enum OddsFormat {
  DECIMAL = 'decimal',
  AMERICAN = 'american',
  FRACTIONAL = 'fractional'
}

export enum Status {
  OPEN = 'OPEN',
  SUSPENDED = 'SUSPENDED',
  CLOSED = 'CLOSED'
}

export enum EventState {
  NOT_STARTED = 'NOT_STARTED',
  STARTED = 'STARTED',
  FINISHED = 'FINISHED'
}

export enum EventFormat {
  MATCH = 'MATCH',
  FUTURES = 'FUTURES'
}

export type Content = {
  type: string;
  provider: string;
}

export enum Breakpoint {
  SMALL = 400,
  MEDIUM = 576,
}

export const SPORTS = {
  TENNIS: 'TENNIS',
  SQUASH: 'SQUASH',
  BADMINTON: 'BADMINTON',
  VOLLEYBALL: 'VOLLEYBALL',
  TABLE_TENNIS: 'TABLE_TENNIS',
  BEACH_VOLLEYBALL: 'BEACH_VOLLEYBALL',
  AMERICAN_FOOTBALL: 'AMERICAN_FOOTBALL',
  BASEBALL: 'BASEBALL',
  BASKETBALL: 'BASKETBALL',
  ICE_HOCKEY: 'ICE_HOCKEY',
  FOOTBALL: 'FOOTBALL',
  SPECIAL_BETS: 'SPECIAL_BETS',
};
