import React from 'react';
import styled, { css } from 'styled-components';
import {
  BetOffer as BetOfferType, Breakpoint, OddsFormat, Status,
} from '../../interfaces/EventWidget';
import Outcome from './Outcome';

const BetOfferWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme: { width } }) => width > Breakpoint.MEDIUM && css`
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding-top: 12px;
  `};
`;

type TitleProps = {
  lowered: boolean;
}

const Title = styled.span<TitleProps>`
  font-weight: bold;
  padding: 12px 0 8px;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: ${({ theme: { colors } }) => colors.black};

  ${({ theme: { width } }) => width > Breakpoint.MEDIUM && css`
    padding: 0;
    flex: 40%;
    text-align: start;
    font-size: 16px;
    line-height: 20px;
  `}

  ${({ theme: { width }, lowered }) => width > Breakpoint.MEDIUM && lowered && css`
    padding-top: 16px;
  `}

`;

type OutcomesProps = {
  single: boolean;
}

const Outcomes = styled.div<OutcomesProps>`
  display: grid;
  grid-gap: 4px 8px;

  grid-template-columns: ${({ theme: { width } }) => {
    if (width < Breakpoint.SMALL) {
      return '1fr';
    }
    return '1fr 1fr';
  }};

  ${({ theme: { width } }) => width > Breakpoint.MEDIUM && css`
    flex: 60%;
  `};

  ${({ single }) => single && css`
    grid-template-columns: 1fr;
  `}
`;

type BetOfferProps = {
  betOffer: BetOfferType;
  oddsFormat: OddsFormat;
  selectedOutcomes: number[];
}

function BetOffer({ betOffer, oddsFormat, selectedOutcomes }: BetOfferProps) {
  return (
    <BetOfferWrapper>
      <Title lowered={betOffer.outcomes.some((outcome) => !!outcome.line)}>
        {betOffer.betDescription}
      </Title>
      <Outcomes single={betOffer.outcomes.length === 1}>
        {betOffer.outcomes.map((outcome, index) => {
          const selected = selectedOutcomes.includes(outcome.id);
          let odds = `${outcome.odds.toFixed(2)}`;
          if (oddsFormat === OddsFormat.AMERICAN) {
            odds = Number(outcome.oddsAmerican) > 0 ? `+${outcome.oddsAmerican}` : outcome.oddsAmerican;
          } else if (oddsFormat === OddsFormat.FRACTIONAL) {
            odds = outcome.oddsFractional;
          }

          return (
            <Outcome
              key={`outcome-${outcome.id}`}
              odds={odds}
              line={outcome.line}
              label={(!outcome.line || index < 2) ? outcome.label : undefined}
              disabled={outcome.status !== Status.OPEN || betOffer.status !== Status.OPEN}
              active={selected}
              onClick={() => (selected
                ? RSIKambiController.removeOutcomes([outcome.id])
                : RSIKambiController.addOutcomes([outcome.id]))}
            />
          );
        })}
      </Outcomes>
    </BetOfferWrapper>
  );
}

export default BetOffer;
