import axios from 'axios';
import { stringify } from 'qs';
import React, { useEffect, useState } from 'react';
import useConfigs from '../../hooks/useConfigs';
import { StreamContent } from '../../interfaces';
import { logger } from '../../services';

interface EventsContentResponse {
  data: {
    [key: number]: Array<{ type: 'STREAM' | 'TIPS' | 'STATS', provider: 'SPORTRADAR' | 'GTG' }>
  }
}

type EventsContentPlayerProps = {
  events: StreamContent['events'];
  containerId: string;
}

function EventsContentPlayer({ events, containerId }: EventsContentPlayerProps) {
  const { api } = useConfigs(['api']);
  const [shownEventId, setShownEventId] = useState<number>(0);

  useEffect(() => {
    const fetchEventsContent = async (eventIds: Array<number> = []) => {
      try {
        if (api && eventIds.length) {
          const { data }: EventsContentResponse = await axios.get(
            `${api.apiUrl}service/sportsbook/events/content`,
            {
              params: {
                cageCode: api.cageCode,
                eventId: eventIds,
              },
              paramsSerializer: (params) => stringify(params, { indices: false }),
            },
          );
          setShownEventId(eventIds.find((eventId) => data[eventId].some(({ type }) => type === 'STREAM')) || 0);
        }
      } catch (error) {
        const { API } = RSIUtils.getLogTags();
        logger.error(`${API} Failed to fetch events content`, {
          ...(error instanceof Error) && { error: error.message || error.toString() },
        });
      }
    };

    fetchEventsContent(events?.map(({ id }) => id));
  }, [events, api]);

  useEffect(() => {
    let destroy: () => void;
    if (shownEventId) {
      ({ destroy } = RSIContentPlayerClient.init({
        containerId,
        initialState: {
          event: {
            id: shownEventId,
          },
        },
      }));
    }
    return () => destroy?.();
  }, [shownEventId, containerId]);

  if (!shownEventId) {
    return null;
  }

  return (
    <div
      id={containerId}
      className="rsi-styles"
    />
  );
}

export default EventsContentPlayer;
