import React from 'react';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(tz);

type TimeProps = {
  time: string;
  format: string;
  timezone: string;
}

function Time({ time, format, timezone }: TimeProps) {
  return (
    <time>
      {dayjs(time).tz(timezone).format(format)}
    </time>
  );
}

export default Time;
