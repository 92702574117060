import React from 'react';
import { StreamContent } from '../../interfaces';
import EventsContentPlayer from './EventsContentPlayer';

type StreamContentPlayerProps = {
  content: StreamContent;
  containerId: string;
}

function StreamContentPlayer({ content, containerId }: StreamContentPlayerProps) {
  const { events } = content;

  return <EventsContentPlayer events={events} containerId={containerId} />;
}

export default StreamContentPlayer;
