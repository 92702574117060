import { AnyAction } from 'redux';
import { ExamplesState } from '../../interfaces/ExamplesState';
import { ExamplesActionTypes } from '../actionTypes';

const initialState: ExamplesState = {
  data: undefined,
};

export default (
  state = initialState,
  { type, payload }: AnyAction = { type: ExamplesActionTypes.DEFAULT },
) => {
  switch (type) {
    case ExamplesActionTypes.GET_EXAMPLE_SUCCESS:
      return {
        ...state,
        data: payload,
      };
    default:
      return state;
  }
};
