import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { stringify } from 'qs';
import { Content, Event as EventType } from '../../interfaces/EventWidget';
import logger from '../../services/logger';
import Event from './Event';
import useConfigs from '../../hooks/useConfigs';

type EventWidgetProps = {
  betOfferId?: number;
}

function EventWidget({ betOfferId }: EventWidgetProps) {
  const [event, setEvent] = useState<EventType>();
  const [eventContent, setEventContent] = useState<Content[]>([]);
  const [statsEnabled, setStatsEnabled] = useState<boolean>(false);
  const { api, sportsSettings } = useConfigs(['api', 'sportsSettings']);

  useEffect(() => {
    const fetchBetOffer = async () => {
      try {
        if (api) {
          const { data } = await axios.get(
            `${api.apiUrl}service/sportsbook/offering/listview/events/betoffers/${betOfferId}`,
            { params: { cageCode: api.cageCode } },
          );
          setEvent(data);
        }
      } catch (error) {
        const { API } = RSIUtils.getLogTags();
        logger.error(
          `${API} Failed to fetch betOffer`,
          {
            ...(error instanceof Error) && { error: error.message || error.toString() },
            betOfferId,
          },
        );
      }
    };

    fetchBetOffer();
  }, [betOfferId, api]);

  useEffect(() => {
    const fetchEventContent = async (eventId: number) => {
      const { data } = await axios.get(`${api?.apiUrl}service/sportsbook/events/content`, {
        params: {
          cageCode: api?.cageCode,
          eventId,
        },
        paramsSerializer: (params) => stringify(params, { indices: false }),
      });
      setEventContent(data[eventId]);
    };
    if (event?.id && api?.apiUrl && api?.cageCode) {
      fetchEventContent(event.id);
    }
  }, [event?.id, api?.apiUrl, api?.cageCode]);

  useEffect(() => {
    const gtgStatsUrlConfigured = !!sportsSettings?.integrations?.gtg?.stats?.url;
    if (gtgStatsUrlConfigured) {
      const gtgStatsAvailable = eventContent.some((content) => content.type === 'STATS' && content.provider === 'GTG');
      setStatsEnabled(gtgStatsAvailable);
    } else {
      const sportRadarUrlConfigured = !sportsSettings?.integrations.sportRadar.url;
      setStatsEnabled(sportRadarUrlConfigured);
    }
  }, [eventContent, sportsSettings]);

  if (!betOfferId || !event || !sportsSettings || !api) {
    return null;
  }

  return (
    <Event
      id={event.id}
      title={event.name}
      start={event.start}
      offerCount={event.offerCount}
      betOffers={event.betOffers}
      dateTimeFormat={`${sportsSettings?.dateTimeFormats.default.date}${sportsSettings?.dateTimeFormats.default.time}`}
      timezone={api?.cageTimeZone}
      stats={statsEnabled}
      sportsEventEnabled={sportsSettings?.sportsEventEnabled}
    />
  );
}

EventWidget.defaultProps = {
  betOfferId: undefined,
};

export default EventWidget;
