import React, { useEffect, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';
import StreamContentPlayer from './components/ContentPlayer/StreamContentPlayer';
import Event from './components/Event/Event';
import EventWidget from './components/Event/EventWidget';
import SGP from './components/SGP';
import SportsbookBanners from './components/SportsbookBanners';
import SportsEvent from './components/SportsEvent';
import VideoPlayer from './components/VideoPlayer';
import useConfigs from './hooks/useConfigs';
import useLocale from './hooks/useLocale';
import useTranslations from './hooks/useTranslations';
import {
  Device, SGPOptions, SportsEventOptions, VideoPlayerOptions, Widget, WidgetComponentEnum,
} from './interfaces';
import { conditionMatches } from './utils/matchers';
import Favorites from './components/Favorites';

type AppProps = {
  widget: Widget;
};

const evalWidget = (w?: Widget, currentUrl?: string, conditions?: string): Widget | null => {
  if (!w) {
    return null;
  }

  if (
    !conditionMatches(conditions, w.condition)
    || !w.availableDevices.includes(RSIUtils.detector.simpleDeviceType as Device)
    || (w.location.urlPieces.length
      && currentUrl
      && !w.location.urlPieces.some((urlPiece: string) => currentUrl.includes(urlPiece))
    )
  ) {
    return null;
  }

  return w;
};
function App({ widget }: AppProps) {
  const locale = useLocale();
  const messages = useTranslations();
  const [currentLocation, setCurrentLocation] = useState<Route>();
  const [conditions, setConditions] = useState<string>();

  const { api } = useConfigs(['api']);

  useEffect(() => {
    const unsubscribeNavHandler = RSINavigationHandler.subscribe(({ to }: NavigationEvent) => {
      setCurrentLocation(to);
    });

    const conditionsSubscription = RSIEventBus.subscribe(
      RSIEventBus.eventTypes.CONDITIONS_CHANGE,
      (newConditions: any) => {
        setConditions(newConditions);
      },
    );

    return () => {
      if (unsubscribeNavHandler) {
        unsubscribeNavHandler();
      }
      if (conditionsSubscription) {
        conditionsSubscription.unsubscribe();
      }
    };
  }, []);

  const shownWidget = useMemo(() => {
    if (!currentLocation) {
      return null;
    }

    const { fullPath } = currentLocation;
    return evalWidget(widget, fullPath, conditions);
  }, [currentLocation, widget, conditions]);

  if (!shownWidget || !api || !locale || !messages) {
    return null;
  }

  const { content, id: widgetId } = shownWidget;

  return (
    <IntlProvider locale={locale} defaultLocale={locale} messages={messages}>
      {content
        .filter(({ schedule }) => RSIUtils.isScheduled(schedule))
        .map(({
          component,
          betOfferId,
          eventContent,
          streamContent,
          source,
          options,
          title,
          initiallyCollapsed,
          maxLegs,
          customParlays,
          hideOnEmptyFavorites,
        }) => {
          const componentKey = `widget-${widgetId}-content-${component}`;

          if (component === WidgetComponentEnum.BANNERS) {
            return <SportsbookBanners key={componentKey} />;
          }
          if (component === WidgetComponentEnum.SPORTS_EVENT && betOfferId && options) {
            const containerId = `${componentKey}-betOffer-${betOfferId}`;
            return (
              <SportsEvent
                key={containerId}
                containerId={containerId}
                betOfferId={betOfferId}
                options={options as SportsEventOptions}
              />
            );
          }
          if (component === WidgetComponentEnum.BET_OFFER) {
            return (
              <EventWidget
                key={`${componentKey}-${betOfferId}`}
                betOfferId={betOfferId}
              />
            );
          }
          if (component === WidgetComponentEnum.BET_OFFER_COMPONENT && eventContent) {
            const {
              id, name, start, offerCount, betOffers,
            } = eventContent;
            return (
              <Event
                key={`${componentKey}-event-${id}`}
                id={id}
                title={name}
                start={start}
                offerCount={offerCount}
                betOffers={betOffers}
                dateTimeFormat={eventContent.dateTimeFormat}
                timezone={eventContent.timezone}
                stats={eventContent.stats}
                sportsEventEnabled={eventContent.sportsEventEnabled}
              />
            );
          }
          if (component === WidgetComponentEnum.STREAM && streamContent) {
            return (
              <StreamContentPlayer
                key={componentKey}
                containerId={componentKey}
                content={streamContent}
              />
            );
          }
          if (component === WidgetComponentEnum.VIDEO && source && options) {
            return (
              <VideoPlayer
                key={componentKey}
                containerId={componentKey}
                source={source}
                options={options as VideoPlayerOptions}
              />
            );
          }
          if (component === WidgetComponentEnum.SGP && options) {
            const containerId = `${componentKey}-${(options as SGPOptions).type}`;
            return (
              <SGP
                key={containerId}
                containerId={containerId}
                options={options as SGPOptions}
                currentLocation={currentLocation}
                title={title}
                initiallyCollapsed={initiallyCollapsed}
                maxLegs={maxLegs}
                customParlays={customParlays}
              />
            );
          }
          if (component === WidgetComponentEnum.FAVORITES) {
            return (
              <Favorites
                initiallyCollapsed={initiallyCollapsed}
                hideOnEmptyFavorites={hideOnEmptyFavorites}
              />
            );
          }
          return null;
        })}
    </IntlProvider>
  );
}

export default App;
