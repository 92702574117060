import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Breakpoint } from '../../interfaces/EventWidget';

const OutcomeWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const Title = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme: { colors } }) => colors.grey};
  text-align: center;
`;

type OutcomeButtonProps = {
  disabled: boolean;
  active: boolean;
}

const OutcomeButton = styled.button<OutcomeButtonProps>`
  flex: 1;
  color: ${({ theme: { colors } }) => colors.blue};
  background-color: ${({ theme: { colors } }) => colors.blue2};
  min-height: 44px;
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  border: 0;
  border-radius: 4px;
  border-bottom: 2px solid rgba(49,96,148,.12);
  box-shadow: inset -5px -5px 4px ${({ theme: { colors } }) => colors.grey2};
  outline: none;
  font-weight: 500;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: all .2s ease-out, box-shadow 0s, border 0s;

  ${({ disabled }) => disabled && css`
      opacity: 0.5;
      background: ${({ theme: { colors } }) => colors.blue2};
      box-shadow: inset -5px -5px 4px ${({ theme: { colors } }) => colors.grey2};
      border-bottom: 0;
      pointer-events: none;
  `}

  @media (hover: hover) and (pointer: fine) {
    ${({ disabled, active }) => !disabled && !active && css`
      &:hover {
        background: ${({ theme: { colors } }) => colors.grey4};
        box-shadow: inset -5px -5px 4px rgba(223, 233, 244, 0.5);

        &:after {
          content: '';
          position: absolute;
          display: block;
          bottom: -1px;
          left: 5%;
          width: 90%;
          height: 2px;
          background: ${({ theme: { colors } }) => colors.blue3};
          opacity: 0.5;
          z-index: -1;
          filter: blur(2px);
          border-radius: 4px;
        }
      }
    `}
  }

  ${({ disabled, active }) => !disabled && active && css`
    background: linear-gradient(144.36deg, ${({ theme: { colors } }) => colors.gold3} 11.14%, ${({ theme: { colors } }) => colors.gold4} 77.48%);
    border-bottom: 0;
    box-shadow: inset -1px -1px 2px ${({ theme: { colors } }) => colors.gold2}, inset 3px 3px 3px ${({ theme: { colors } }) => colors.gold};
    color: ${({ theme: { colors } }) => colors.black2};
  `}

  ${({ theme: { width } }) => width > Breakpoint.MEDIUM && css`
    font-size: 14px;
    line-height: 18px;
  `}
`;

const Label = styled.div`
  padding-left: 4px;
  padding-right: 12px;
  flex: 1;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`;

type OddsProps = {
  active: boolean;
}

const Odds = styled.div<OddsProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  color: ${({ theme: { colors } }) => colors.blue};

  ${({ theme: { width } }) => width > Breakpoint.MEDIUM && css`
    font-size: 16px;
    line-height: 21px;
  `}

  ${({ active }) => active && css`
    color: ${({ theme: { colors } }) => colors.black2};
  `}
`;

type OutcomeProps = {
  odds: string;
  line: number;
  label?: string;

  disabled: boolean;
  active: boolean;

  onClick: () => void;
}

function Outcome({
  odds, line, label, disabled, active, onClick,
}: OutcomeProps) {
  const [formattedLine, setFormattedLine] = useState<string>();

  useEffect(() => {
    if (line) {
      if (line > 0) {
        setFormattedLine(`+${line}`);
      } else {
        setFormattedLine(`${line}`);
      }
    }
  }, [line]);

  return (
    <OutcomeWrapper>
      <Title>
        {line ? label : null}
      </Title>
      <OutcomeButton
        disabled={disabled}
        active={active}
        onClick={onClick}
      >
        <Label>{formattedLine || label}</Label>
        <Odds active={active}>{odds}</Odds>
      </OutcomeButton>
    </OutcomeWrapper>
  );
}

Outcome.defaultProps = {
  label: undefined,
};

export default Outcome;
