import React from 'react';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';
import { InitArgs } from './interfaces';

function Module({
  widget,
  initialState,
}: React.ComponentProps<typeof App> & InitArgs) {
  return (
    <Provider store={store(initialState)}>
      <App widget={widget} />
    </Provider>
  );
}

export default Module;
