import { BetOffer } from './EventWidget';
import { ScheduleGroup } from './Schedule';

export enum WidgetComponentEnum {
  BANNERS = 'banners',
  BET_OFFER = 'betOffer',
  BET_OFFER_COMPONENT = 'betOfferComponent',
  SPORTS_EVENT = 'sportsEvent',
  STREAM = 'stream',
  VIDEO = 'video',
  SGP = 'sgp',
  FAVORITES = 'favorites'
}

export type Device = 'DESKTOP' | 'MOBILE';

export type Location = {
  urlPieces: string[];
  containerId: string;
};

export type StreamContent = {
  events?: Array<ContentPlayerEvent>;
};

export interface CustomParlay {
  description: string;
  eventId: number;
  outcomeIds: number[];
}

export type WidgetContent = {
  component: WidgetComponentEnum;
  betOfferId?: number;
  eventContent?: {
    id: number;
    name: string;
    start: string;
    offerCount: number;
    betOffers: BetOffer[];
    dateTimeFormat: string;
    timezone: string;
    stats: boolean;
    sportsEventEnabled: boolean;
  };
  streamContent?: StreamContent;
  source?: string;
  title?: string;
  options?: VideoPlayerOptions | SGPOptions | SportsEventOptions;
  schedule?: ScheduleGroup[];
  theme?: {
    variant: string;
  };
  initiallyCollapsed?: boolean;
  maxLegs?: number;
  customParlays?: CustomParlay[];
  hideOnEmptyFavorites?: boolean;
};

export type VideoPlayerOptions = {
  autoPlay: boolean;
  loop: boolean;
  fullscreenEnabled: boolean;
  pipEnabled: boolean;
};

export type SGPOptions = {
  type: 'auto' | 'groupId';
  groupId?: number;
}

export type HouseSpecialOptions = {
  type: SportsEventType;
  themeMode: string;
  headingText: string;
  subheadingText: string;
  betOfferDescription: string;
};

export type SportsEventOptions =
  | HouseSpecialOptions

export enum SportsEventType {
  HOUSE_SPECIALS = 'HOUSE_SPECIALS',
}

export type Widget = {
  id: string;
  content: WidgetContent[];
  availableDevices: Device[];
  condition: string;
  location: Location;
};

export type ContentPlayerEvent = {
  id: number;
  comment?: string;
};
