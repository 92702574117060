import React, { useEffect } from 'react';

function SportsbookBanners() {
  useEffect(() => {
    const onBannersClickListener = (event: any) => {
      if (
        event.path
        && event.path?.some(
          (path: any) => path
            && path.classList
            && path.classList.contains('multi-carousel-item'),
        )
      ) {
        RSIAnalytics.event({
          category: 'Banner',
          action: 'Banner clicked',
          label: `${event.target?.getAttribute('alt')} clicked`,
        });
      }
    };
    document.body.addEventListener('click', onBannersClickListener);

    RSIEventBus.publish(
      RSIEventBus.eventTypes.SPORTSBOOK_BANNERS_MOUNTED,
      undefined,
      { withHistory: true },
    );

    return () => document.body.removeEventListener('click', onBannersClickListener);
  }, []);

  return (
    <div
      className="sportsbook-banner-container"
    />
  );
}

export default React.memo(SportsbookBanners);
