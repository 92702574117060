(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("$script"), require("Qs"), require("React"), require("ReactDOM"), require("ReactIntl"), require("ReactRedux"), require("Redux"), require("ReduxThunk"), require("axios"), require("dayjs"), require("dayjs_plugin_timezone"), require("dayjs_plugin_utc"), require("reduxLogger"), require("styled"));
	else if(typeof define === 'function' && define.amd)
		define("RSIUIWidgetClient", ["$script", "Qs", "React", "ReactDOM", "ReactIntl", "ReactRedux", "Redux", "ReduxThunk", "axios", "dayjs", "dayjs_plugin_timezone", "dayjs_plugin_utc", "reduxLogger", "styled"], factory);
	else if(typeof exports === 'object')
		exports["RSIUIWidgetClient"] = factory(require("$script"), require("Qs"), require("React"), require("ReactDOM"), require("ReactIntl"), require("ReactRedux"), require("Redux"), require("ReduxThunk"), require("axios"), require("dayjs"), require("dayjs_plugin_timezone"), require("dayjs_plugin_utc"), require("reduxLogger"), require("styled"));
	else
		root["RSIUIWidgetClient"] = factory(root["$script"], root["Qs"], root["React"], root["ReactDOM"], root["ReactIntl"], root["ReactRedux"], root["Redux"], root["ReduxThunk"], root["axios"], root["dayjs"], root["dayjs_plugin_timezone"], root["dayjs_plugin_utc"], root["reduxLogger"], root["styled"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__879__, __WEBPACK_EXTERNAL_MODULE__448__, __WEBPACK_EXTERNAL_MODULE__24__, __WEBPACK_EXTERNAL_MODULE__314__, __WEBPACK_EXTERNAL_MODULE__568__, __WEBPACK_EXTERNAL_MODULE__566__, __WEBPACK_EXTERNAL_MODULE__894__, __WEBPACK_EXTERNAL_MODULE__647__, __WEBPACK_EXTERNAL_MODULE__300__, __WEBPACK_EXTERNAL_MODULE__760__, __WEBPACK_EXTERNAL_MODULE__882__, __WEBPACK_EXTERNAL_MODULE__94__, __WEBPACK_EXTERNAL_MODULE__377__, __WEBPACK_EXTERNAL_MODULE__404__) {
return 